<template>
  <div>
    <hero
      img="https://image.freepik.com/photos-gratuite/femme-vetements-verts_144627-35678.jpg"
      text="EXPERTISE EN CONFORT THERMIQUE ET DOMOTIQUE"
      :subText="presentation"
      buttonTitle=""
    />

<!--    <find-us
      uri="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2894.917360497011!2d5.384993415824433!3d43.483194971424496!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12c9ed31c0eb9911%3A0x3d4e5febbd151db1!2sTexas%20de%20France!5e0!3m2!1sfr!2sfr!4v1606817148977!5m2!1sfr!2sfr"
    />-->

    <contact-us
    />
  </div>
</template>

<script>
export default {
  name: "Home",

  metaInfo: { title: "Bienvenue" },

  data: () => ({

    presentation : "Depuis près de vingt ans, le département R&D de Texas de France conçoit et développe dans ses laboratoires d'Aix-en-Provence des produits de qualité, fiables et faciles à installer et utiliser. \n" +
        "Nos produits allient performance et respect des souhaits des consommateurs tels que les économies d'énergie et la réduction de l'impact écologique. \n" +
        "Nous proposons une large gamme de produits qui s'adaptent à tous les besoins et toutes les envies et sont certifiés CE et NF, ce qui garantit un haut niveau d'exigence en terme de sécurité et de traçabilité. \n" +
        "Toujours en quête d'innovation, nous déposons régulièrement de nouveaux brevets qui garantissent les caractéristiques et les performances exclusives de nos produits.",

    partners: [
      {
        name: "",
        ico: ""
      }
    ]
  }),

  components: {
    hero: () => import("@/components/mixins/Hero"),
    contactUs: () => import("@/components/mixins/ContactUs"),
//    findUs: () => import("@/components/mixins/Map")
  }
};
</script>
